function add0(m) {
  return m < 10 ? '0' + m : m
}
export default {
  props: ['name', 'value', 'fieldLength'],
  data() {
    return {
      currentValue: this.value
    }
  },
  methods: {
    onInputEvent(value) {
      this.$emit('input', value, this.name)
    },
    reset() {
      this.currentValue = ''
    },
    formartDate(timestamp, type, week) {
      var time = new Date(timestamp)
      if (!timestamp) {
        return ''
      }
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      var h = time.getHours()
      var w = time.getDay()
      var mm = time.getMinutes()
      var s = time.getSeconds()
      var result = ''
      var numberChinese = '日一二三四五六'
      switch (type) {
        case 'year':
          result = y
          break
        case 'month':
          result = y + '-' + add0(m)
          break
        case 'day':
          result = y + '-' + add0(m) + '-' + add0(d)
          break
        case 'hour':
          result = y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h)
          break
        case 'minutes':
          result = y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm)
          break
        case 'seconds':
          result = y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s)
          break
        case 'all':
          result = y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s)
          break
        default:
          result = y + '-' + add0(m) + ' ' + add0(d) + ' ' + add0(h) + ':' + add0(mm)
          break
      }
      if (week) {
        result += ' 星期' + numberChinese[w]
      }
      return result
    }
  },
  watch: {
    value(val) {
      this.currentValue = val
    }
  }
}
