<template>
  <div>
    <el-upload
      ref="upload"
      :action="url"
      :headers="headers"
      :limit="1"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      class="upload-demo"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-exceed="handleExceed"
      :accept="accept"
      :on-error="handleError"
      :file-list="fileList"
      :disabled="isUpload"
    >
      <el-button size="small" type="primary" :disabled="isUpload" plain
        >点击上传</el-button
      >
      <div slot="tip" class="el-upload__tip">
        {{ remarks || "只能上传图片、PDF、Word、Excel、PPT、TXT文件" }}
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import formMixins from "./mixins/form-model";
import Cookies from "js-cookie";
// import { fetchFileList } from '@/api/file'
export default {
  name: "FileSelector",
  components: {},
  mixins: [formMixins],
  props: {
    accept: {
      type: Object,
      accept: ""
    },
    remarks: {
      type: String,
      default: "只能上传图片、PDF、Word、Excel、PPT、TXT文件"
    },
    isUpload: {
      type: Boolean,
      depfault: false
    },
    apiUrl: {
      type: Object,
      default() {
        return {
          baseApi: Cookies.get("Base-Api"),
          uploadSingle: "/File/UploadSingleFile",
          uploadChunk: "/File/UploadChunkFile",
          mergeChunk: "/File/MergeFilesByChunk",
          getFiles: "/File/GetListByIds"
        };
      }
    }
  },
  // inject: ['editUpload'],
  inject: {
    editUpload: {
      default: () =>
        function() {
          console.log("不存在此函数");
        }
    }
  },
  data() {
    return {
      url: this.apiUrl.baseApi + this.apiUrl.uploadSingle,
      // url: "https://jsonplaceholder.typicode.com/posts/",
      headers: { Authorization: Cookies.get("Admin-Token") },
      fileList: [],
      dialogImageUrl: this.value,
      dialogVisible: false,
      currentValue: this.value,
      fileIds: this.value,
      fileIdList: []
    };
  },
  computed: {},
  mounted() {
    if (this.value && this.value !== "") {
      this.fileIdList = this.value.split(",");
      this.fileList = this.getFiles();
    }
  },
  methods: {
    beforeUpload() {
      this.editUpload(true);
    },
    handleSuccess(response, file, fileList) {
      const fileId = response.Result.fileId;
      this.fileIdList.push(fileId);
      this.fileIds = this.fileIdList.join(",");
      // this.$emit('input', this.fileIds)
      this.$emit("input", response.Result.Id);
      this.$emit("path", response.Result.Id);
      this.getFiles();
      this.editUpload(false);
      this.$message({
        type: "success",
        message: "文件已上传到服务器!"
      });
    },
    handleError() {
      this.editUpload(false);
    },
    handleRemove(file, fileList) {
      var delIndex = this.fileIdList.indexOf(file.id);
      this.fileIdList.splice(delIndex, 1);
      this.fileIds = this.fileIdList.join(",");
      this.$emit("input", this.fileIds, this.name);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      // this.dialogVisible = true
    },
    handleExceed(file, fileList) {
      this.$message({
        type: "warning",
        message: "只能上传1个文件！!"
      });
    },
    clearFileList() {
      this.$refs.upload.clearFiles();
    },
    getFiles() {
      var newFiles = [];
      if (this.fileIds && this.fileIds !== "") {
        console.log(this.fileIds);
        // this.$http.get(`${this.apiUrl.getFiles}?ids=${this.fileIds}`).then(response => {
        //   var files = response.Result
        //   files.map(i => {
        //     const file = { id: i.Id, name: i.FileName, url: i.ViewFilePath }
        //     newFiles.push(file)
        //   })
        // })
        // fetchFileList(this.fileIds).then(response => {
        //   var files = response.Result
        //   files.map(i => {
        //     const file = { id: i.Id, name: i.FileName, url: i.ViewFilePath }
        //     newFiles.push(file)
        //   })
        // })
      }
      return newFiles;
    }
  }
};
</script>

<style lang="scss" scoped>
>>> .el-upload-list__item-name {
  overflow: inherit;
  text-overflow: inherit;
  white-space: normal;
  line-height: 30px;
  max-width: 100%;
}
/deep/ .el-upload__tip {
  line-height: 32px !important;
}
</style>
