import request from "@/utils/request";
const controller = "/VocationalEducation/InternshipImplement";

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });
const getAllList = params =>
  request({
    url: `${controller}/GetAllList`,
    method: "get",
    params
  });
const getInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: { id }
  });
const queryListByEnterpriseId = params =>
  request({
    url: `${controller}/QueryListByEnterpriseId`,
    method: "get",
    params
  });
const queryListByJobId = params =>
  request({
    url: `${controller}/QueryListByJobId`,
    method: "get",
    params
  });
const uploadFile = params =>
  request({
    url: `${controller}/UploadFile`,
    method: "get",
    params
  });
const filed = data =>
  request({
    url: `${controller}/Filed`,
    method: "post",
    data
  });
export {
  getPageList,
  getAllList,
  getInfo,
  queryListByEnterpriseId,
  queryListByJobId,
  uploadFile,
  filed
};
