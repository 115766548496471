import request from "@/utils/request";
const controller = "/VocationalEducation/JobApply";

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });
const getAllList = params =>
  request({
    url: `${controller}/GetAllList`,
    method: "get",
    params
  });
const getApplyInfo = id =>
  request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: {
      id
    }
  });

const insert = data =>
  request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
const deleteApply = id =>
  request({
    url: `${controller}/Delete`,
    method: "post",
    params: {
      id
    }
  });
const approve = data =>
  request({
    url: `${controller}/Approve`,
    method: "post",
    data
  });
const getInternshipJobList = params =>
  request({
    url: `${controller}/GetInternshipJobList`,
    method: "get",
    params
  });
const queryApplyJobCatelog = params =>
  request({
    url: `${controller}/QueryApplyJobCatelog`,
    method: "get",
    params
  });
export {
  getPageList,
  getAllList,
  getApplyInfo,
  insert,
  deleteApply,
  approve,
  getInternshipJobList,
  queryApplyJobCatelog
};
