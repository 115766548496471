<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
        <span>岗位：</span>
        <el-select
          class="mr-20"
          v-model="queryParams.JobId"
          filterable
          clearable
        >
          <el-option
            v-for="item in jobList"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="initialize">搜索</el-button>
      </div>
      <!-- <div class="right">
        <el-button
          type="primary"
          icon="el-icon-upload"
          @click="handleMultipleUpload"
          >批量上传实践计划</el-button
        >
      </div> -->
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      height="640px"
      @selection-change="handleSelectionChange"
      v-loading="loading"
    >
      <el-table-column type="selection" width="55" label="选择">
      </el-table-column>
      <template v-for="(item, index) in tableConfig">
        <el-table-column
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="needWidth(item.prop)"
        >
          <template slot-scope="scope">
            <span v-if="item.prop === 'SchoolName'">{{
              scope.row["StudentInfo"].SchoolName
            }}</span>
            <span v-else-if="item.prop === 'MajorName'">{{
              scope.row["StudentInfo"].MajorName
            }}</span>
            <div v-else-if="item.prop === 'Name'" class="u-flex">
              <el-image
                style="width: 32px; height: 32px"
                :src="
                  scope.row['StudentInfo'].PhotoFullPath ||
                    'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
                "
                class="u-radius-32 mr-16"
              >
              </el-image>
              {{ scope.row["StudentInfo"].Name }}
            </div>
            <span v-else-if="item.prop === 'SexStr'">{{
              scope.row["StudentInfo"].SexStr
            }}</span>
            <span v-else-if="item.prop === 'ClassInfo'">{{
              scope.row["StudentInfo"].ClassName
            }}</span>
            <span v-else-if="item.prop === 'PlanTime'"
              >{{ formartDate(scope.row["PlanStartTime"], "day") }}
              至
              {{ formartDate(scope.row["PlanEndTime"], "day") }}</span
            >
            <span v-else-if="item.prop === 'ActualTime'">
              <span>{{
                filterTimeStr(
                  scope.row["ActualStartTime"],
                  scope.row["ActualEndTime"]
                )
              }}</span>
            </span>
            <span v-else-if="item.prop === 'Salary'">{{
              `${scope.row["JobInfo"].SalaryMin}K-${scope.row["JobInfo"].SalaryMax}K `
            }}</span>
            <div v-else-if="item.prop === 'Plan'" class="file">
              <template v-for="el in scope.row['AttachementList']">
                <div :key="el.Id" v-if="el.Type === 1">
                  <el-tooltip effect="dark" placement="bottom">
                    <div slot="content">
                      <el-button
                        type="text"
                        size="mini"
                        icon="el-icon-camera"
                        @click="handlePreview(el)"
                        >预览 {{ el.FileName }}</el-button
                      >
                      <br />
                      <el-button
                        type="text"
                        size="mini"
                        icon="el-icon-download"
                      >
                        <a
                          class="el-button--text"
                          :href="'/files/zxj/' + el.FilePath"
                          :download="el.FileName"
                          >下载 {{ el.FileName }}</a
                        >
                      </el-button>
                    </div>
                    <div class="el-icon-paperclip">
                      <span>{{ el.FileName }}</span>
                    </div>
                  </el-tooltip>
                </div>
              </template>
            </div>
            <div v-else-if="item.prop === 'Tripartite'" class="file">
              <template v-for="el in scope.row['AttachementList']">
                <div :key="el.Id" v-if="el.Type === 2">
                  <el-tooltip effect="dark" placement="bottom">
                    <div slot="content">
                      <el-button
                        type="text"
                        size="mini"
                        icon="el-icon-camera"
                        @click="handlePreview(el)"
                        >预览 {{ el.FileName }}</el-button
                      >
                      <br />
                      <el-button
                        type="text"
                        size="mini"
                        icon="el-icon-download"
                      >
                        <a
                          class="el-button--text"
                          :href="'/files/zxj/' + el.FilePath"
                          :download="el.FileName"
                          >下载 {{ el.FileName }}</a
                        >
                      </el-button>
                    </div>
                    <div class="el-icon-paperclip">
                      <span>{{ el.Type === 2 ? el.FileName : null }}</span>
                    </div>
                  </el-tooltip>
                </div>
              </template>
            </div>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scoped">
          <el-button
            type="text"
            icon="el-icon-upload2"
            class="btn-color"
            @click="handleUpload(scoped.row.Id, 'plan')"
            >上传计划</el-button
          >
          <el-button
            type="text"
            icon="el-icon-folder-add"
            @click="handleUpload(scoped.row.Id, 'tripartite')"
            >上传三方协议</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="type === 'plan' ? '上传计划' : '上传三方协议'"
      :visible.sync="dialogVisible"
      :before-close="dialogClose"
    >
      <el-form ref="form" :model="form">
        <el-form-item label="文件：" label-width="100px">
          <FileSelector ref="fileUpload" v-model="form.file" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer form-footer">
        <el-button @click="dialogClose()">取 消</el-button>
        <el-button type="primary" @click="submit()" :loading="uploadLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showFile" title="查看文件" width="1100px">
      <Preview v-if="showFile" :fileId="fileId" />
    </el-dialog>
  </div>
</template>

<script>
import { getPageList, uploadFile } from "@/api/internshipImplement";
import { insert } from "@/api/implementAttacment";
import { queryApplyJobCatelog } from "@/api/jobApply";
import contentTools from "@/mixins/content-tools";
import Preview from "@/components/Preview";
import FileSelector from "@/components/Fileselector";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
export default {
  components: {
    FileSelector,
    Preview
  },
  mixins: [contentTools],
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    }
  },
  data() {
    return {
      showFile: false,
      fileId: "",
      dialogVisible: false,
      form: {},
      rowId: "",
      type: "",
      loading: false,
      uploadLoading: false,
      multipleList: [],
      isMultipleUpload: false,
      queryParams: {
        PageSize: 999,
        PageIndex: 1
      },
      tableData: [],
      tableConfig: [
        {
          label: "姓名",
          prop: "Name"
        },
        {
          label: "性别",
          prop: "SexStr"
        },
        {
          label: "学校",
          prop: "SchoolName"
        },
        {
          label: "专业",
          prop: "MajorName"
        },
        {
          label: "班级",
          prop: "ClassInfo"
        },
        {
          label: "实践岗位",
          prop: "JobName"
        },
        {
          label: "计划实践时间",
          prop: "PlanTime"
        },
        {
          label: "实际实践时间",
          prop: "ActualTime"
        },
        {
          label: "薪资待遇",
          prop: "Salary"
        },
        {
          label: "实践计划",
          prop: "Plan"
        },
        {
          label: "三方协议",
          prop: "Tripartite"
        }
      ],
      majorList: [],
      jobList: []
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        if (this.RoleType === StaffTypeEnum.School) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.SchoolId = temp.Employer.Id;
        } else {
          this.queryParams.SchoolId = null;
        }
        await this.getTableData();
        await this.getJobList();
      } catch (err) {
        console.log("err:", err);
      } finally {
        this.loading = false;
      }
    },
    async getTableData() {
      const res = await getPageList(this.queryParams);
      if (res.IsSuccess) {
        this.tableData = res.Result.Data;
      }
    },
    needWidth(val) {
      const arr200 = [
        "Name",
        "ClassInfo",
        "SchoolName",
        "MajorName",
        "Plan",
        "Tripartite",
        "ActualTime",
        "PlanTime"
      ];
      const arr100 = ["JobName"];
      if (arr200.indexOf(val) !== -1) {
        return 200;
      } else if (arr100.indexOf(val) !== -1) {
        return 100;
      } else {
        return;
      }
    },
    async submit() {
      let params = {};
      if (this.isMultipleUpload) {
        let arr = [];
        this.multipleList.forEach(el => {
          arr.push(el.Id);
        });
        params.ids = arr;
        params.fileIds = [this.form.file];
      } else {
        params.ImplementId = this.rowId;
        params.FileId = this.form.file;
      }
      params.Type = this.type === "plan" ? 1 : 2;
      console.log(params);
      try {
        this.uploadLoading = true;
        let IsSuccess = false;
        if (this.isMultipleUpload) {
          const res = await uploadFile(params);
          IsSuccess = res.IsSuccess;
        } else {
          const res = await insert(params);
          IsSuccess = res.IsSuccess;
        }
        if (IsSuccess) {
          this.$message.success("上传成功");
          this.dialogClose();
          this.initialize();
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        this.uploadLoading = false;
      }
    },
    handleUpload(id, val) {
      this.rowId = id;
      this.type = val;
      this.dialogVisible = true;
      this.multipleList = [];
      this.isMultipleUpload = false;
    },
    handleMultipleUpload() {
      this.isMultipleUpload = true;
      this.dialogVisible = true;
      this.type = "plan";
    },
    async getJobList() {
      const res = await queryApplyJobCatelog({
        schoolId: this.queryParams.SchoolId
      });
      if (res.IsSuccess) {
        this.jobList = res.Result;
      }
    },
    dialogClose() {
      this.$refs.fileUpload.clearFileList();
      this.dialogVisible = false;
    },
    handleSelectionChange(arr) {
      this.multipleList = arr;
    },
    handlePreview(el) {
      this.showFile = true;
      this.fileId = el.FileFullPath;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.file {
  color: #598DF3;
}
.el-button--text {
  text-decoration: none;
}
</style>
